
<hr>
<h1 style="text-align: center;">{{'app.info5' | translate}}</h1>
<hr>

<h1 style="text-align: center;">Conferencia: "Averroes, el filósofo andalusí que abrió nuevos caminos en el pensamiento medieval". Fundación March (Madrid)</h1>
<div class="container">
    <div class="card">
        <a href="https://canal.march.es/es/coleccion/averroes-filosofo-andalusi-que-abrio-nuevos-caminos-pensamiento-medieval-346" target="_blank" rel="noopener noreferrer">
            <img src="./../../../../assets/img/March5.webp" class="img-fluid" alt="...">
        </a>   
    </div>
</div>
<hr>
<h1 style="text-align: center;">Averroes, el Comentador</h1>
<div class="container">
    <div class="card">
        <a href="https://vimeo.com/361667616" target="_blank" rel="noopener noreferrer">
            <img src="./../../../../assets/img/El comentador1.webp" loading="lazy" class="img-fluid" alt="...">
        </a>   
    </div>
</div>
<br>
<hr>
<h1 style="text-align: center;">Entrevista en "Entre líneas" ADEH TV</h1>
<div class="container">
    <div class="card">
        <a href="https://www.youtube.com/embed/a3bKKDt9E58?si=aB2zigSapOAHMtHy" target="_blank" rel="noopener noreferrer">
            <img src="./../../../../assets/img/El comentador1.webp" loading="lazy" class="img-fluid" alt="...">
        </a>   
    </div>
</div>
<br>

    

