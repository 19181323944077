

import {Component} from '@angular/core';

export interface SeleccionElement {
  titulo: string;
  src: string;
}



const ELEMENT_DATA: SeleccionElement[] = [
  {titulo: 'Prólogo al libro "La filosofía en Al Ándalus"', src:"./../../assets/pdf/AF_Filosofía en al-Andalus Extract[7-16].pdf"},
  {titulo: 'Averroes sobre las facultades locomotriz y desiderativa...', src:"./../../assets/pdf/Averroes sobre las facultades locomotriz y desiderativa....pdf"},
  {titulo: 'La recepción de la Ética aristotélica en Averroes y su impacto en el mundo latino medieval', src:"./../../assets/pdf/La recepción de la Ética aristotélica en Averroes y su impacto en el mundo latino medieval.pdf"},
  {titulo: 'Prólogo "Sobre la melancolía, por la diversidad cultural, contra la guerra"', src:"./../../assets/pdf/Sobre la melancolía.pdf"},
  {titulo: 'Prólogo al libro de Tomás de Aquino, "Exposición de la Política de Aristóteles"', src:"./../../assets/pdf/Prólogo a Tomás de Aquino, Exposición de la Política de Aristóteles.pdf"},
  {titulo: 'Sobre Ibn Gabirol', src:"./../../assets/pdf/Sobre Ibn Gabirol.pdf"},
  {titulo: 'Prólogo al libro "Hacia un nuevo Averroes"', src:"./../../assets/pdf/Prólogo al libro Hacia un nuevo Averroes.pdf"},
  {titulo: 'Prefacio a La Filosofía Medieval. De al-Farabi a Ockham', src:"./../../assets/pdf/Prefacio a La Filosofía Medieval. De al-Farabi a Ockham.pdf"},
  {titulo: 'Adolfo Sánchez Vázquez: el legado fecundo de un filósofo marxista y el testimonio ejemplar de un republicano del exilio', src:"./../../assets/pdf/Adolfo Sanchez Vázquez, necrologica.pdf"},
  {titulo: 'Cristóbal Aguilar, artista del pueblo, alma de la serranía', src:"./../../assets/pdf/Cristóbal Aguilar.pdf"},
  {titulo: 'Mahmud Ali Makki: el sabio egipcio que tanto amaba a España', src:"./../../assets/pdf/Mahmud Ali Makki, el sabio egipcio que tanto amaba a España.pdf"},
  {titulo: 'Necrología de Mohamed Arkoun', src:"http://www.elpais.com/articulo/Necrologicas/Mohamed/Arkoun/intelectual/arabe/innovador/islamologo/elpepinec/20100918elpepinec_2/Tes"},
  {titulo: 'Necrología de Mohamed Ábed Yabri', src:"http://www.elpais.com/articulo/Necrologicas/Mohamed/Yabri/renovador/pensamiento/arabe/elpepinec/20100509elpepinec_2/Tes/"},
  {titulo: 'Prólogo al libro "Averroes, El sabio cordobés que iluminó Europa"', src:"./../../assets/pdf/Prologo Averroes.pdf"},
  {titulo: 'Congreso Gramsci, resumen de la comunicacion', src:"./../../assets/pdf/Congreso Gramsci, resumen de la comunicacion.pdf"},
  {titulo: 'Traducción al turco de artículo "La influencia de Averroes en la filosofía escolástica y renacentista"', src:"./../../assets/pdf/Traduccion_al turco_de_articulo_sobre_Averroes.pdf"},
  {titulo: 'Ibn Rushd’s influence on scholastic and renaissance philosophy', src:"./../../assets/pdf/IBN RUSHDS INFLUENCE ON SCHOLASTIC AND RENAISSANCE PHILOSOPHY.pdf"},
  {titulo: 'Prólogo al libro "Maestros de Occidente, Estudios sobre el pensamiento Andalusi"', src:"./../../assets/pdf/Prologo a  Maestros de Occidente.pdf"},
  {titulo: 'El antiguo Egipto en la cultura griega', src:"./../../assets/pdf/El antiguo egipto en la cultura griega.pdf"},
  {titulo: 'Prólogo al libro "Átomos, hombres y dioses"', src:"./../../assets/pdf/Prologo a atomos, hombres y dioses.pdf"},
  {titulo: 'La reforma almohade', src:"./../../assets/pdf/La reforma almohade.pdf"},
  {titulo: 'Averroes, Europa y la modernidad', src:"./../../assets/pdf/Averroes Europa y la modernidad.pdf"},
  {titulo: 'Prólogo al libro "Sobre el intelecto"', src:"./../../assets/pdf/Prologo a Sobre el intelecto.pdf"},
  {titulo: 'Conclusiones de "El problema de los intelectuales y el concepto de cultura en Gramsci', src:"./../../assets/pdf/El problema de los intelectuales y el concepto de cultura en Gramsci (Conclusiones).pdf"},
  {titulo: 'Prólogo al libro "Palomares', src:"./../../assets/pdf/Palomares (Prologo).pdf"},
  {titulo: 'Artículo sobre Adolfo Sánchez Vázquez', src:"./../../assets/pdf/Articulo sobre SAnchez VAzquez.pdf"},
  {titulo: 'Prólogo al libro "Personajes y escenas de Málaga', src:"./../../assets/pdf/Prologo a Personajes y escenas de Malaga.pdf"},
  {titulo: 'Said Al-Andalusí: Una evaluación del papel de la investigación y la ciencia en el-Andalus y en el mundo', src:"./../../assets/pdf/Conferencia Internacional Toledo.pdf"},
  {titulo: 'Córdoba, casa de las ciencias', src:"./../../assets/pdf/Córdoba, casa de las ciencias, 3 de febrero de 2011.pdf"},
  
  
];

@Component({
  selector: 'app-selecciontextos',
  styleUrls: ['./selecciontextos.component.css'],
  templateUrl: './selecciontextos.component.html'
})
export class SelecciontextosComponent {
  displayedColumns: string[] = ['titulo'];
  dataSource = ELEMENT_DATA;
  clickedRows = new Set<SeleccionElement>();
}


